import revive_payload_client_4sVQNw7RlN from "/usr/src/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/usr/src/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/usr/src/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/usr/src/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/usr/src/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/usr/src/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_iGLjBbor9q from "/usr/src/app/node_modules/nuxt-jsonld/dist/runtime/plugin.js";
import plugin_t2GMTTFnMT from "/usr/src/app/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import nuxt_plugin_03YjkxYbK5 from "/usr/src/app/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/usr/src/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _1_repository_6GaJkDQT7y from "/usr/src/app/plugins/1.repository.ts";
import _2_i18n_EuExFkfPVU from "/usr/src/app/plugins/2.i18n.ts";
import client_init_client_5CS63MILCo from "/usr/src/app/plugins/client-init.client.ts";
import head_xR53WE0jXG from "/usr/src/app/plugins/head.ts";
import lazyload_ehEjmux8k8 from "/usr/src/app/plugins/lazyload.ts";
import mixins_rwh75tZ8hy from "/usr/src/app/plugins/mixins.ts";
import registration_modal_client_4xRfLmF0H3 from "/usr/src/app/plugins/registration-modal.client.ts";
import router_WMnTGednOQ from "/usr/src/app/plugins/router.ts";
import sentry_3AyO8nEfhE from "/usr/src/app/plugins/sentry.ts";
import useEmitter_8q5Lrsmm6i from "/usr/src/app/plugins/useEmitter.ts";
import vue_gtm_client_stBjLl0OeM from "/usr/src/app/plugins/vue-gtm.client.ts";
import vue_matomo_client_fjseBvFIOY from "/usr/src/app/plugins/vue-matomo.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_iGLjBbor9q,
  plugin_t2GMTTFnMT,
  nuxt_plugin_03YjkxYbK5,
  chunk_reload_client_UciE0i6zes,
  _1_repository_6GaJkDQT7y,
  _2_i18n_EuExFkfPVU,
  client_init_client_5CS63MILCo,
  head_xR53WE0jXG,
  lazyload_ehEjmux8k8,
  mixins_rwh75tZ8hy,
  registration_modal_client_4xRfLmF0H3,
  router_WMnTGednOQ,
  sentry_3AyO8nEfhE,
  useEmitter_8q5Lrsmm6i,
  vue_gtm_client_stBjLl0OeM,
  vue_matomo_client_fjseBvFIOY
]