<template>
    <div class="page page-form-recommend">
        <h1>{{ $t('formRecommend.h1') }}</h1>
        <div class="text-md-center col-md-10 mx-auto">
            <h2>{{ $t('formRecommend.h2') }}</h2>
            <p>
                {{ $t('formRecommend.p') }}
            </p>
        </div>

        <div class="col-md-8 mx-auto mt-5">
            <div v-show="sentForm" class="alert alert-success error-alert-message px-5" role="alert" fade>
                {{ $t('formRecommend.send') }}
            </div>

            <form v-if="!sentForm" @submit.prevent="sendFormRecommend">
                <h3>{{ $t('formRecommend.h3') }}</h3>
                <div class="row mb-2">
                    <div class="col-md-6 mb-3">
                        <fieldset class="form-group">
                            <label for="form-input-1" class="mb-1"
                                >{{ $t('formRecommend.fullName') }} <span class="text-danger">*</span></label
                            >
                            <input id="form-input-1" v-model="fullName" class="form-control" type="text" required />
                        </fieldset>
                    </div>
                    <div class="col-md-6 mb-3">
                        <fieldset class="form-group">
                            <label class="mb-1" for="form-input-2">E-mail: <span class="text-danger">*</span></label>
                            <input id="form-input-2" v-model="email" type="email" class="form-control" required />
                        </fieldset>
                    </div>
                </div>

                <fieldset class="form-group mb-3">
                    <textarea
                        v-model="message"
                        class="form-control w-100"
                        rows="4"
                        wrap="soft"
                        :placeholder="$t('formRecommend.textarea')"
                        required
                    />
                </fieldset>

                <div class="d-flex">
                    <input
                        id="conditions"
                        v-model="isAgreeWithTermsChecked"
                        :class="{ 'is-active': isAgreeWithTermsChecked }"
                        class="checkbox"
                        type="checkbox"
                        value=""
                        name="conditions"
                        required
                    />

                    <label class="form-check-label mx-3" for="conditions">
                        <b>
                            {{ $t('formRecommend.agree.text') }}
                            <a :href="$t('formRecommend.agree.url')" target="_blank" class="text-primary">
                                {{ $t('formRecommend.agree.link') }}
                            </a>
                        </b>
                    </label>
                </div>

                <div class="row mt-3">
                    <div class="col-12 col-md-6 py-3">
                        <span><b class="text-primary">*</b>{{ $t('formRecommend.fill') }}</span>
                    </div>
                    <div class="col-12 col-md-6 text-end">
                        <button type="submit" class="btn btn-150 btn-primary d-block d-md-inline-block rounded-25">
                            {{ $t('formRecommend.btnText') }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script lang="ts">
export default {
    data() {
        return {
            message: '',
            email: '',
            fullName: '',
            sentForm: false,
            lang: this.$t('locale'),
            isAgreeWithTermsChecked: false,
        }
    },

    methods: {
        async sendFormRecommend(): Promise<void> {
            const data = {
                fullName: this.fullName,
                email: this.email,
                message: this.message,
            }

            await this.$repository.sendFormRecommend(data)
            this.sentForm = true
        },
    },
}
</script>

<style lang="scss" scoped>
.page.page-form-recommend {
    @media (min-width: 1000px) {
        margin: 3em 0;
    }
}
.page-form-recommend {
    max-width: 1235px;
    background: #fff url('/nuxt-assets/icons/bg-bubbles.svg') 0 40px no-repeat;
    padding: 2em 1em;
    background-size: 100% 100%;

    @media (min-width: 1000px) {
        padding: 5em;
    }

    h1,
    .h1 {
        font-size: 30px;
        color: $color-dark;
        margin-bottom: 1em;
        font-weight: normal;

        @media (min-width: 1000px) {
            margin-bottom: 2em;
        }
    }
    h2,
    h3,
    h4 {
        margin-bottom: 1em;
    }
    textarea,
    input {
        font-size: 16.1px;
    }
    form {
        font-size: 14px;
    }

    .alert {
        background: $color-white;
        position: relative;
        box-shadow: inset 0 0 0 1px rgba($color-white, 0.2);
        padding-left: 50px;

        &-success {
            padding: 15px;
            border: 0;
            background: $color-green-dark;
            color: $color-white;
        }
    }
}
</style>
