import { useGtm } from '@gtm-support/vue-gtm'
import getDevice from '~/extensions/getDevice'
import { useLayoutStore } from '~/stores/layout'
import { useBasketStore } from '~/stores/basket'
import { GtmEcommerceEventTypeEnum } from '~/models/DataLayer/GtmEcommerceEventTypeEnum'
import GtmPageData from '~/models/DataLayer/GtmPageData'
import GtmEcommerceEvent from '~/models/DataLayer/GtmEcommerceEvent'
import { useAbTestStore } from '~/stores/abTest'
import { transformBasketProductToGtmProduct } from '~/utils/transformBasketProductToGtmProduct'
import { useCookie } from '#imports'

export default class DataLayer {
    static gtag(): void {
        // eslint-disable-next-line prefer-rest-params
        window.dataLayer?.push(arguments)
    }

    static push(data: object | Array<any>): void {
        const gtm = useGtm()
        if (!gtm) {
            return
        }

        if (useRuntimeConfig().public.gtm.debug) {
            window.dataLayer = [...(gtm.dataLayer() || [])]
        }

        gtm.trackEvent(data)
    }

    static pushPageViewToDataLayer(gtmPage: GtmPageData): void {
        const layoutStore = useLayoutStore()
        const basketStore = useBasketStore()

        let user = basketStore.gtmDataLayerUser

        if (user === undefined) {
            user = {
                type: 'anonymous',
            }
        }

        let historyStateBack = history.state.back

        if (historyStateBack) {
            historyStateBack = location.host + historyStateBack
        } else {
            historyStateBack = gtmPage.referer
        }

        const head = {
            ...layoutStore.gtmDataLayerHead,
            environment: useRuntimeConfig().public.gtm.debug ? 'development' : 'production',
        }

        const abTestStore = useAbTestStore()
        const abTests = abTestStore.abTests

        this.push({
            event: 'page_view',
            ...head,
            device: getDevice(),
            user,
            page: {
                url: location.href,
                title: document.title,
                ...gtmPage,
                referer: historyStateBack ? `${location.protocol}//${historyStateBack}` : null,
            },
            abTests,
            _clear: true,
        })

        this.pushLoginRegistrationEventToDataLayer()
    }

    static pushLoginRegistrationEventToDataLayer(): void {
        const date = new Date()
        date.setSeconds(date.getSeconds() - 3600)

        const loginEventDataFromCookie = useCookie<{ event: string; method: string }>('loginEventData').value
        if (loginEventDataFromCookie !== undefined) {
            this.push(loginEventDataFromCookie)

            useCookie('loginEventData', { expires: date }).value = ''
        }

        const registrationEventDataFromCookie = useCookie<{ event: string; method: string; type: string }>('registrationEventData').value
        if (registrationEventDataFromCookie !== undefined) {
            this.push(registrationEventDataFromCookie)

            useCookie('registrationEventData', { expires: date }).value = ''
        }
    }

    static pushEcommerceEventToDataLayer(event: GtmEcommerceEvent): void {
        const layoutStore = useLayoutStore()
        const basketStore = useBasketStore()

        const { itemListName, carouselName, type, products, listName, targetListPosition, productAmount, page, productIdReferrer } = event

        let cart = basketStore.basket.products.map((product) => transformBasketProductToGtmProduct(product))
        cart = cart.filter((product) => product.quantity > 0)

        // const filteredProducts = products.filter((product) => product?.type !== 'catalog_banner')
        const transformedProducts = products.map((product, index) => {
            const listPosition = products.length > 1 ? index : targetListPosition || 0
            product.item_list_name = itemListName
            product.carousel_name = carouselName
            product.list_position = listPosition + 1
            if (productAmount) {
                product.quantity = productAmount
            }

            return product
        })

        this.push({
            event: type,
            value: transformedProducts?.[0]?.price || null,
            ecommerce: {
                ...(listName && { list_name: listName }),
                ...((type === GtmEcommerceEventTypeEnum.AddToCart || type === GtmEcommerceEventTypeEnum.RemoveFromCart) && { cart }),
                ...(page && { page }),
                ...((type === GtmEcommerceEventTypeEnum.AddToCart || type === GtmEcommerceEventTypeEnum.ClickToProductDetail) && {
                    product_id_referrer: productIdReferrer,
                }),
                ...(event.ecommerceAdditionalData && { ...event.ecommerceAdditionalData }),
                currency: layoutStore.gtmDataLayerHead.currency,
                country: layoutStore.gtmDataLayerHead.country,
                products: transformedProducts,
                value: basketStore.basket.price.afterDiscountPriceNoVat,
            },
            ...(event.additionalData && { ...event.additionalData }),
            _clear: true,
        })
    }

    static getListName(name: string | null, type: string): string | null {
        if (name === null) {
            return null
        }

        const typeTitle = {
            category: 'Category',
            brand: 'Brand',
            producer: 'Producer',
            product_line: 'Product line',
        }[type]

        if (!typeTitle) {
            return null
        }

        return `${typeTitle}: ${name.trim()}`
    }
}
