export default (): string => {
    const device = useDevice()

    switch (true) {
        case device.isTablet:
            return 'tablet'
        case device.isMobile:
            return 'mobile'
        case device.isDesktop:
            return 'desktop'
        default:
            return 'unknown'
    }
}
