import { defineStore } from 'pinia'
import GeneralResponse from '~/models/Api/GeneralResponse'

interface RefreshTokenResponse {
    token: {
        accessToken: string
        refreshToken: string
        expiresIn: number
    }
}

export const useAuthStore = defineStore('auth', {
    state: () => ({
        accessToken: null as string | null,
        refreshToken: null as string | null,
        accessTokenExpiresIn: null as string | null,
    }),
    actions: {
        async initFromCookies() {
            const accessTokenCookie = useAccessTokenCookie()
            const refreshTokenCookie = useRefreshTokenCookie()
            const accessTokenExpiresInCookie = useAccessTokenExpiresInCookie()

            this.accessToken = accessTokenCookie.value || null
            this.refreshToken = refreshTokenCookie.value || null
            this.accessTokenExpiresIn = accessTokenExpiresInCookie.value || null

            if ((parseInt(this.accessTokenExpiresIn || '') || 0) < Date.now() / 1000 && this.accessToken && this.refreshToken) {
                await this.renewRefreshToken()
            }
        },

        async initFromLogin({ accessToken, expiresIn, refreshToken }: { accessToken: string; expiresIn: number; refreshToken: string }) {
            const accessTokenCookie = useAccessTokenCookie()
            const refreshTokenCookie = useRefreshTokenCookie()
            const accessTokenExpiresInCookie = useAccessTokenExpiresInCookie()

            this.accessToken = accessTokenCookie.value = accessToken
            this.refreshToken = refreshTokenCookie.value = refreshToken
            this.accessTokenExpiresIn = accessTokenExpiresInCookie.value = expiresIn

            if (parseInt(this.accessTokenExpiresIn) < Date.now() / 1000 && this.accessToken && this.refreshToken) {
                await this.renewRefreshToken()
            }
        },

        async renewRefreshToken() {
            const refreshToken = this.refreshToken
            if (refreshToken === null) {
                return
            }

            const accessTokenCookie = useAccessTokenCookie()
            const refreshTokenCookie = useRefreshTokenCookie()
            const accessTokenExpiresInCookie = useAccessTokenExpiresInCookie()

            const headers = {
                refreshToken,
            }
            const config = useRuntimeConfig()

            const refreshTokenResponse: GeneralResponse<RefreshTokenResponse> = await $fetch(
                `${config.public.apiUrl}/api/v2/token/refresh`,
                {
                    method: 'POST',
                    headers,
                }
            )

            if (!refreshTokenResponse || !refreshTokenResponse.response) {
                this.logout()

                return
            }

            this.accessToken = accessTokenCookie.value = refreshTokenResponse.response.token.accessToken
            this.refreshToken = refreshTokenCookie.value = refreshTokenResponse.response.token.refreshToken
            this.accessTokenExpiresIn = accessTokenExpiresInCookie.value = refreshTokenResponse.response.token.expiresIn
        },

        logout() {
            const accessTokenCookie = useAccessTokenCookie()
            const refreshTokenCookie = useRefreshTokenCookie()
            const accessTokenExpiresInCookie = useAccessTokenExpiresInCookie()

            this.accessToken = accessTokenCookie.value = null
            this.refreshToken = refreshTokenCookie.value = null
            this.accessTokenExpiresIn = accessTokenExpiresInCookie.value = null
        },
    },
})
