import { defineStore } from 'pinia'

export const usePerformanceStore = defineStore('performance', {
    state: () => ({
        list: {} as { [name: string]: number },
    }),
    actions: {
        add(name: string, value: number) {
            if (process.server) {
                this.list[name] = (this.list[name] || 0) + Math.round(value)
            }
        },
    },
})
