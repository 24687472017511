import * as Sentry from '@sentry/vue'
import { App } from 'vue'
import { defineNuxtPlugin, useNuxtApp } from '#app'

declare global {
    interface Window {
        SMap: any
        loadAndOpenAmioChat: Function
        smartform: {
            rebindAllForms: Function
            beforeInit: Function
            setClientId: Function
            enableSetAutocomplete: Function
            getInstanceIds: Function
            getInstance: Function
            afterInit: Function
        }
        _paq: any
        ApplePaySession: any | null
    }
}

export const useSentry = (vueApp?: App) => {
    if (vueApp === undefined) {
        vueApp = useNuxtApp().vueApp
    }

    const config = useRuntimeConfig()

    if (!config.public.sentry.enabled) {
        return null
    }

    // Sentry.addTracingExtensions()
    Sentry.init({
        app: [vueApp],
        dsn: config.public.sentry.dsn,
        // integrations: [new Sentry.BrowserTracing()],
    })

    vueApp.mixin(
        Sentry.createTracingMixins({
            trackComponents: true,
            timeout: 2000,
            hooks: ['activate', 'mount', 'update'],
        })
    )

    Sentry.attachErrorHandler(vueApp, {
        logErrors: false,
        attachProps: true,
        trackComponents: true,
        timeout: 2000,
        hooks: ['activate', 'mount', 'update'],
    })

    return {
        sentrySetContext: Sentry.setContext,
        sentrySetUser: Sentry.setUser,
        sentrySetTag: Sentry.setTag,
        sentryAddBreadcrumb: Sentry.addBreadcrumb,
        sentryCaptureException: Sentry.captureException,
    }
}

export default defineNuxtPlugin(({ vueApp }) => ({
    provide: {
        ...useSentry(vueApp),
    },
}))
