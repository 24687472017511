// @ts-ignore
import VueMatomo from 'vue-matomo'

export default defineNuxtPlugin(({ vueApp }): void => {
    const { enabled, siteId, host } = useRuntimeConfig().public.matomo

    if (!enabled) {
        return
    }

    vueApp.use(VueMatomo, {
        host,
        siteId,
        enableLinkTracking: false,
    })
})
