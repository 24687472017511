import validate from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import clear_45flash_45messages_45global from "/usr/src/app/middleware/clearFlashMessages.global.ts";
import delete_45unused_45get_45parameters_45global from "/usr/src/app/middleware/deleteUnusedGetParameters.global.ts";
import delete_45url_45mask_45get_45parameters_45global from "/usr/src/app/middleware/deleteUrlMaskGetParameters.global.ts";
import hide_45modal_45global from "/usr/src/app/middleware/hideModal.global.ts";
import referral_45global from "/usr/src/app/middleware/referral.global.ts";
import manifest_45route_45rule from "/usr/src/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  clear_45flash_45messages_45global,
  delete_45unused_45get_45parameters_45global,
  delete_45url_45mask_45get_45parameters_45global,
  hide_45modal_45global,
  referral_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}