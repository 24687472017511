<template>
    <h1>Empty page no layout no GTM</h1>
</template>

<script lang="ts">
export default {
    layout: 'empty',

    async created() {
        // await this.$i18n.setLocale(this.$config.locale)
    },
}
</script>
