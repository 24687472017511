<template>
    <div class="bg-white w-100">
        <Welcome />

        <div class="row bg-white mx-auto mt-4">
            <UserAccount />
            <Card />
        </div>

        <div class="mt-4 w-100 b-grey">
            <div id="menu_user" class="card" no-body>
                <!--
                <b-tabs card fill>
                    <b-tab :title="$t('user.tabs.orders')" @click="hrefRoute('/objednavky')"></b-tab>
                    <b-tab :title="$t('user.tabs.vouchers')" active>
                        <div class="card-text">
                            <Vouchers :items="vouchers" />
                        </div>
                    </b-tab>
                    <b-tab :title="$t('user.tabs.evaluation')" @click="hrefRoute('/hodnocení')"></b-tab>
                    <b-tab :title="$t('user.tabs.user')" @click="hrefRoute('/muj-ucet')"></b-tab>
                    <b-tab :title="$t('user.tabs.complaint')" @click="hrefRoute('/moje-reklamace')"></b-tab>
                    <b-tab :title="$t('user.tabs.favorite')" @click="hrefRoute('/me-oblibene')"></b-tab>
                    <b-tab :title="$t('user.tabs.club')" @click="hrefRoute('/user-review-club')"></b-tab>
                </b-tabs>
                -->
            </div>
        </div>
    </div>
</template>

<script lang="ts">
// @ts-nocheck
import Welcome from '~/components/layout/user/Welcome.vue'
import Card from '~/components/layout/user/Card.vue'
import UserAccount from '~/components/layout/user/UserAccount.vue'
import GtmPageData from '~/models/DataLayer/GtmPageData'
import DataLayer from '~/extensions/DataLayer'
import LuigisBoxEventApi from '~/extensions/LuigisBoxEventApi'

export default {
    components: { Welcome, Card, UserAccount },

    middleware: 'authenticated',

    async asyncData({ $cookies, $repository }) {
        let vouchers = {}

        try {
            const response = await $repository.getVouchers({
                accessToken: $cookies.get('accessToken'),
            })
            vouchers = response.vouchers || []
        } catch (error) {}

        return {
            vouchers,
        }
    },

    async created() {
        // await this.$i18n.setLocale(this.$config.locale)
    },

    mounted(): void {
        const page = {
            type: 'user',
        } as GtmPageData

        DataLayer.pushPageViewToDataLayer(page)
        LuigisBoxEventApi.sendPageView()
    },

    methods: {
        hrefRoute(href) {
            window.location = href
        },
    },
}
</script>
<style lang="scss" scoped>
.bg-white {
    background: #fff;
}

.tab-content {
    .card-body {
        max-width: $max-width-container;
        margin: 0 auto;

        .card-text {
            background: rgb(255 255 255);

            @media (min-width: 540px) {
                padding: 30px;
            }

            @media (max-width: 540px) {
                margin: 0 -1.5rem;
            }
        }
    }
}

#menu_user {
    background: transparent;
    border: none;
    margin: auto;

    .card-header {
        background: transparent;
        max-width: $max-width-container;
        margin: 0 auto;
        border: none;

        .nav-tabs .nav-link,
        .nav-tabs .nav-link:hover,
        .nav-tabs .nav-link.active,
        .nav-tabs .nav-item.show .nav-link {
            background: transparent;
            color: $color-cta;
            border: none;
            text-transform: uppercase;
            font-size: 13px;
        }

        .nav-tabs .nav-link.active {
            border-bottom: 2px solid $color-cta;
        }
    }
}

.shadow-account-box {
    box-shadow: rgba(183, 183, 183, 0.35) 2px 2px 4px;
    border: 1px solid $color-grey-dark;
    background: #fff;
    max-width: 360px;

    .bg-grey-light {
        background-color: rgb(246, 246, 246);
    }
}
</style>
