import mitt from 'mitt'
import { defineNuxtPlugin } from '#app'
import { Bus } from '~/plugins/mixins'
const emitter = mitt()

export default defineNuxtPlugin(() => {
    return {
        provide: {
            bus: {
                $on: emitter.on,
                $off: emitter.off,
                $emit: emitter.emit,
            } as Bus,
        },
    }
})
