<template>
    <h1>test</h1>
</template>

<script lang="ts">
export default {
    async created() {
        // await this.$i18n.setLocale(this.$config.locale)
    },
}
</script>
