export default {
    search: {
        getWord: (text: string, queries: string[]): string => {
            if (text) {
                let modifiedText = text
                queries.forEach((query) => {
                    const regex = new RegExp(query, 'gi')
                    modifiedText = modifiedText.replace(regex, `<span class="search-term">${query}</span>`)
                })
                return modifiedText
            }

            return ''
        },
    },
}
