import { createGtm, loadScript } from '@gtm-support/vue-gtm'

export default defineNuxtPlugin(({ vueApp, hook }): void => {
    const { enabled, id, debug } = useRuntimeConfig().public.gtm
    const route = useRoute()

    vueApp.use(
        createGtm({
            id,
            defer: false,
            compatibility: false,
            enabled,
            debug,
            loadScript: false,
            vueRouter: useRouter(),
            trackOnNextTick: false,
        })
    )

    if (route.query.nogtm) {
        return
    }

    hook('page:finish', () => {
        loadScript(id, {
            defer: true,
            compatibility: true,
        })
    })
})
