import type { RouteLocationNormalized } from 'vue-router'

export default defineNuxtRouteMiddleware((to: RouteLocationNormalized) => {
    const promoCode: string = to.query?.ref as string

    if (promoCode && promoCode !== '') {
        useCookie('promoCode', {
            maxAge: 2592000, // 30 days
            secure: true,
        }).value = promoCode
    }
})
