const czSkPluralizationRule = (number) => {
    if (number === 0) {
        return 0
    }

    if (number === 1) {
        return 1
    }

    if (number >= 2 && number <= 4) {
        return 2
    }

    return 3
}

export default function () {
    return {
        pluralizationRules: {
            cz: czSkPluralizationRule,
            sk: czSkPluralizationRule,
            ro: (number) => {
                if (number === 1) {
                    return 0
                }

                if (number === 0 || (number % 100 > 0 && number % 100 < 20)) {
                    return 1
                }

                return 2
            },
        },
    }
}
