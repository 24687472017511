function interopDefault(promise) {
    return promise.then((m) => m.default || m)
}

const at = () => interopDefault(import('./at.js'))
const cz = () => interopDefault(import('./cz.js'))
const hu = () => interopDefault(import('./hu.js'))
const ro = () => interopDefault(import('./ro.js'))
const sk = () => interopDefault(import('./sk.js'))

const messages = {
    at,
    cz,
    hu,
    ro,
    sk,
}

export default async function provideMessages(locale) {
    return await messages[locale]()
}
