import { defineStore } from 'pinia'
import Product from '~/models/Product/Product'
import GeneralResponse from '~/models/Api/GeneralResponse'
import PwaLayoutResponse from '~/models/Api/PwaLayoutResponse'
import MenuItem from '~/models/MenuItem'
import Banner from '~/models/components/Banner'
import GtmDataLayerHead from '~/models/DataLayer/GtmDataLayerHead'
import useApiResponseCache from '~/composables/useApiResponseCache'
import { AbTest } from '~/models/AbTest/AbTest'
import { usePerformanceStore } from '~/stores/performace'
import MenuBanner from '~/models/MenuBanner'
import HeadlineBar from '~/models/HeadlineBar'
import ProgressBar from '~/models/ProgressBar'

export const useLayoutStore = defineStore('layout', {
    state: () => ({
        facebookLoginEnabled: false,
        googleLoginEnabled: true,
        pinnedCategories: [] as Banner[],
        menuBanners: [] as MenuBanner[],
        menuItems: [] as MenuItem[],
        menuItemProducts: [] as Product[],
        cartBoxBanner: null as Banner | null,
        upsellPopupBanner: null as Banner | null,
        headlineBar: null as HeadlineBar | null,
        saleBanners: [] as Banner[],
        gtmDataLayerHead: {} as GtmDataLayerHead,
        socialProfiles: [] as string[],
        zoeEnabled: false,
        progressBars: [] as ProgressBar[],
        showSidebar: false,
        sidebarMode: '',
        structuredData: {
            title: '',
            rating: 0,
        },
        leftSideBanner: null as Banner | null,
        rightSideBanner: null as Banner | null,
        abTests: [] as AbTest[],
    }),
    actions: {
        async fetchData() {
            const config = useRuntimeConfig()
            const performanceStore = usePerformanceStore()
            const start = performance.now()
            const response: GeneralResponse<PwaLayoutResponse> = await useApiResponseCache<GeneralResponse<PwaLayoutResponse>>(
                '/api/v2/pwa/layout',
                300,
                async () => {
                    const layoutResponse: GeneralResponse<PwaLayoutResponse> = await $fetch(`${config.public.apiUrl}/api/v2/pwa/layout`, {
                        method: 'POST',
                        data: {
                            menuMaxLevels: 1,
                        },
                    })

                    // @ts-ignore
                    layoutResponse.response.menuItems = layoutResponse.response.menuItems.map((itemL1) => {
                        return {
                            id: itemL1.id,
                            imageCdn: itemL1.image,
                            imageAlt: itemL1.imageAlt,
                            isHighlighted: itemL1.isHighlighted,
                            items: itemL1.items.map((itemL2) => {
                                return {
                                    id: itemL2.id,
                                    imageCdn: '', // itemL2.image,
                                    imageAlt: itemL2.imageAlt,
                                    items: [] /* itemL2.items.map((itemL3) => {
                                        return {
                                            id: itemL3.id,
                                            name: itemL3.name,
                                            url: itemL3.url,
                                        }
                                    }), */,
                                    name: itemL2.name,
                                    objectId: itemL2.objectId,
                                    url: itemL2.url,
                                }
                            }),
                            name: itemL1.name,
                            objectId: itemL1.objectId,
                            url: itemL1.url,
                        }
                    })

                    layoutResponse.response.menuItemProducts = []
                    layoutResponse.response.menuBanners = []

                    return layoutResponse
                }
            )
            performanceStore.add('layout', performance.now() - start)

            this.init(response.response)
        },

        init(data: PwaLayoutResponse) {
            this.abTests = data.abTests || []
            this.setFacebookLoginEnabled(data.facebookLoginEnabled)
            this.setGoogleLoginEnabled(data.googleLoginEnabled)
            this.setPinnedCategories(data.pinnedCategories)
            this.setMenuBanners(data.menuBanners)
            this.setMenuItems(data.menuItems)
            this.setMenuItemProducts(data.menuItemProducts)
            this.setCartBoxBanner(data.cartBoxBanner)
            this.setUpsellPopupBanner(data.upsellPopupBanner)
            this.setHeadlineBar(data.headlineBar)
            this.setSaleBanners(data.saleBanners)
            this.setGtmDataLayerHead(data.gtmDataLayerHead)
            this.setSocialProfiles(data.socialProfiles)
            this.setZoeEnabled(data.zoeEnabled)
            this.setProgressBars(data.progressBars)
            this.setLeftSideBanner(data.leftSideBanner)
            this.setRightSideBanner(data.rightSideBanner)
        },

        setFacebookLoginEnabled(enabled: boolean) {
            this.facebookLoginEnabled = enabled
        },

        setGoogleLoginEnabled(enabled: boolean) {
            this.googleLoginEnabled = enabled
        },

        setPinnedCategories(pinnedCategories: Banner[]) {
            this.pinnedCategories = pinnedCategories
        },

        setMenuBanners(menuBanners: MenuBanner[]) {
            this.menuBanners = menuBanners
        },

        setMenuItems(menuItems: MenuItem[]) {
            this.menuItems = menuItems
        },

        setMenuItemProducts(menuItemProducts: Product[]) {
            this.menuItemProducts = menuItemProducts
        },

        setCartBoxBanner(cartBoxBanner: Banner | null) {
            this.cartBoxBanner = cartBoxBanner
        },

        setUpsellPopupBanner(upsellPopupBanner: Banner | null) {
            this.upsellPopupBanner = upsellPopupBanner
        },

        setHeadlineBar(headlineBar: HeadlineBar | null) {
            this.headlineBar = headlineBar
        },

        setSaleBanners(saleBanners: Banner[]) {
            this.saleBanners = saleBanners
        },

        setGtmDataLayerHead(gtmDataLayerHead: GtmDataLayerHead) {
            this.gtmDataLayerHead = gtmDataLayerHead
        },

        setSocialProfiles(socialProfiles: string[]) {
            this.socialProfiles = socialProfiles
        },

        setStructuredData(structuredData: { title: ''; rating: 0 }) {
            this.structuredData = structuredData
        },

        setShowSidebarMode(sidebarMode: string) {
            this.showSidebar = true
            this.sidebarMode = sidebarMode || ''
        },

        closeSidebar() {
            this.showSidebar = false
        },

        setShowSidebar(showSidebar: boolean) {
            this.showSidebar = showSidebar
        },

        setSidebarMode(sidebarMode: string) {
            this.sidebarMode = sidebarMode
        },

        setLeftSideBanner(leftSideBanner: Banner | null) {
            this.leftSideBanner = leftSideBanner
        },

        setRightSideBanner(rightSideBanner: Banner | null) {
            this.rightSideBanner = rightSideBanner
        },

        setZoeEnabled(zoeEnabled: boolean) {
            this.zoeEnabled = zoeEnabled
        },

        setProgressBars(progressBars: ProgressBar[]) {
            this.progressBars = progressBars
        },
    },
})
