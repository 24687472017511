// @ts-nocheck
declare module '#app' {
    interface NuxtApp {
        $lazyload: (el: HTMLElement) => void
    }
}

export default defineNuxtPlugin((nuxtApp) => {
    const options = {
        rootMargin: '0px 0px 400px 0px',
        loadedClass: 'isLoaded',
        loadingClass: 'isLoading',
        defaultImage: '/nuxt-assets/images/lazy-load-error.svg',
        appendClass: 'lazyload',
    }
    const isPictureChild = (el) => el.parentNode && el.parentNode.tagName.toLowerCase() === 'picture'

    const setAttribute = (el, attribute) => {
        const dataAttribute = `data-${attribute}`

        if (Array.isArray(el)) for (const item of el) setAttribute(item, attribute)
        else if (el.getAttribute(dataAttribute)) {
            el.setAttribute(attribute, el.getAttribute(dataAttribute))
            el.removeAttribute(dataAttribute)
            el.onerror = () => {
                el.setAttribute('src', options.defaultImage)
            }
            if (el.parentNode.load) el.parentNode.load()
        }
    }
    const setClass = (el) => {
        el.classList.remove(options.loadingClass)
        if (options.loadedClass) el.classList.add(options.loadedClass)
    }

    const setEvents = (el) => {
        const tagName = el.tagName.toLowerCase()
        let eventName = 'load'
        if (['video', 'audio'].includes(tagName)) eventName = 'loadeddata'

        el.addEventListener(eventName, () => {
            if (isPictureChild(el)) {
                if (el.parentNode.getAttribute('data-not-lazy') === null) setClass(el.parentNode)
                else el.parentNode.removeAttribute('data-not-lazy')
            } else setClass(el)
        })
    }

    let observer: IntersectionObserver | null = null
    if (process.client) {
        observer = new IntersectionObserver(
            (entries, self) => {
                for (const entry of entries) {
                    if (!entry.isIntersecting) {
                        continue
                    }

                    const el = entry.target
                    if (!isPictureChild(el) && options.loadingClass) el.classList.add(options.loadingClass)

                    setAttribute(el, 'poster')
                    const source = el.querySelectorAll('source')

                    if (source.length) {
                        setAttribute([...source], 'src')
                        setAttribute([...source], 'srcset')
                    } else {
                        setAttribute(el, 'src')
                        setAttribute(el, 'srcset')
                    }

                    self.unobserve(entry.target)
                }
            },
            { rootMargin: '0px 0px 400px 0px' }
        )
    }

    nuxtApp.vueApp.directive('lazy-load', {
        beforeMount(el) {
            setEvents(el)
            if (options.defaultImage && el.tagName.toLowerCase() === 'img') el.src = options.defaultImage
            if (!isPictureChild(el) && options.appendClass) el.classList.add(options.appendClass)
        },

        mounted(el, binding) {
            const value = binding.value
            if (value) {
                const media: HTMLElement[] = []
                el.innerHTML = value

                media.push(...el.querySelectorAll('img'))

                for (const element of media) {
                    element.classList.add(options.appendClass)
                    if (observer) observer.observe(element)
                }
            } else {
                if (observer) observer.observe(el)
                if (options.defaultImage && el.tagName.toLowerCase() === 'img') el.src = options.defaultImage
            }
        },

        getSSRProps() {
            return {}
        },
    })

    nuxtApp.vueApp.directive('not-lazy', {
        beforeMount(el) {
            for (const item of [...el.querySelectorAll('source'), ...el.querySelectorAll('img')]) {
                setAttribute(item, 'src')
                setAttribute(item, 'srcset')
            }

            if (el.tagName.toLowerCase() !== 'picture') el.removeAttribute('data-not-lazy')
        },

        getSSRProps() {
            return {}
        },
    })

    return {
        provide: {
            lazyload(el) {
                if (observer) observer.observe(el)
            },
        },
    }
})
