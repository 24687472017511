export default async <T>(inputCacheKey: string, ttl: number, callback: () => Promise<T>, useCache = true): Promise<T> => {
    if (!useCache || process.client) {
        return await callback()
    }

    const redis = await useRedis()

    const cacheKey = `eshop-pwa:${inputCacheKey}`
    const record = await redis.get(cacheKey)

    if (record) {
        return JSON.parse(record)
    }

    const response = await callback()
    redis.set(cacheKey, JSON.stringify(response), 'EX', ttl)

    return response
}
