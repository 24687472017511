import { defineStore } from 'pinia'
// import md5 from 'md5'
import { AbTest, AbTestVariant } from '~/models/AbTest/AbTest'

export const useAbTestStore = defineStore('abTest', {
    state: () => ({
        abTests: [] as AbTestVariant[],
    }),
    actions: {
        setAbTestNames(abTests: AbTest[]) {
            try {
                const abTestsCookie = useAbTestCookie()
                const abTestsFromLocalStorage =
                    typeof localStorage !== 'undefined' ? JSON.parse(localStorage.getItem('abTests') || 'null') : null
                this.abTests = abTestsCookie.value || abTestsFromLocalStorage || []

                for (let i = 0; i < abTests?.length; i++) {
                    let found = false
                    for (let j = 0; j < this.abTests.length; j++) {
                        if (abTests[i].name === this.abTests[j].name) {
                            found = true
                        }
                    }

                    if (!found) {
                        // const selectedVariant = Number.parseInt(md5(abTests[i].name + headers['user-agent'] + headers['x-forwarded-for']).substring(0, 4), 16) % abTests[i].variantsCount

                        this.abTests.push({
                            name: abTests[i].name,
                            selectedVariant: Math.floor(Math.random() * abTests[i].variantsCount),
                        })
                    }
                }

                localStorage.setItem('abTests', JSON.stringify(this.abTests))
                abTestsCookie.value = this.abTests
            } catch (e) {}
        },

        getVariant(abTestName: string): number | null {
            for (let i = 0; i < this.abTests.length; i++) {
                if (this.abTests[i].name === abTestName) {
                    return this.abTests[i].selectedVariant
                }
            }

            return null
        },
    },
})
