import { createI18n } from 'vue-i18n'
import provideMessages from '../locales'
import pluralizationRules from '~/config/i18n'

export default defineNuxtPlugin(async ({ vueApp }) => {
    const config = useRuntimeConfig()
    const locale = config.public.locale
    const i18n = createI18n({
        legacy: false,
        globalInjection: true,
        locale,
        pluralRules: pluralizationRules().pluralizationRules,
        messages: {
            [locale]: await provideMessages(locale),
        },
    })

    // @ts-ignore
    vueApp.use(i18n)
})
