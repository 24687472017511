<script setup lang="ts">
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const items = [
    {
        title: t('page.404.subcategories.item01.title'),
        url: t('page.404.subcategories.item01.url'),
        img: t('page.404.subcategories.item01.img'),
    },
    {
        title: t('page.404.subcategories.item02.title'),
        url: t('page.404.subcategories.item02.url'),
        img: t('page.404.subcategories.item02.img'),
    },
    {
        title: t('page.404.subcategories.item03.title'),
        url: t('page.404.subcategories.item03.url'),
        img: t('page.404.subcategories.item03.img'),
    },
    {
        title: t('page.404.subcategories.item04.title'),
        url: t('page.404.subcategories.item04.url'),
        img: t('page.404.subcategories.item04.img'),
    },
    {
        title: t('page.404.subcategories.item05.title'),
        url: t('page.404.subcategories.item05.url'),
        img: t('page.404.subcategories.item05.img'),
    },
    {
        title: t('page.404.subcategories.item06.title'),
        url: t('page.404.subcategories.item06.url'),
        img: t('page.404.subcategories.item06.img'),
    },
]
</script>

<template>
    <section>
        <h2 class="page404__subtitle">{{ $t('page.404.subtitle') }}</h2>
        <div class="page404__category">
            <span v-for="(item, i) in items" :key="item.title">
                <a v-if="$t(`page.404.subcategories.item0${i + 1}.title`).length" :href="item.url" class="page404__item">
                    <img :src="item.img" class="page404__item-img" :alt="item.title" data-not-lazy />
                    <span class="page404__item-name">{{ item.title }}</span>
                </a>
            </span>
        </div>
    </section>
</template>

<style scoped lang="scss"></style>
