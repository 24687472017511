interface RegistrationModalCookie {
    pageLoadedCounter: number
    modalShown: boolean
}

export default () => {
    return useCookie<RegistrationModalCookie>('registrationModal', {
        maxAge: 2592000, // 30 days
        secure: true,
        default() {
            return {
                pageLoadedCounter: 0,
                modalShown: false,
            }
        },
    })
}
