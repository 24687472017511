import Repository from '~/api/repository'
import { useSentry } from '~/plugins/sentry'

// declare module '#app' {
//     interface NuxtApp {
//         $repository: Repository
//     }
// }
//
// declare module '@nuxt/types' {
//     interface Context {
//         $repository: Repository
//     }
// }

export default defineNuxtPlugin(() => {
    const config = useRuntimeConfig()
    const sentry = useSentry()

    return {
        provide: {
            repository: new Repository(config, sentry),
        },
    }
})
