<template>
    <iframe src="https://www.pilulka.cz/pilulka-car-game/game.html" height="600" class="game"></iframe>
</template>

<script lang="ts">
export default {}
</script>

<style lang="scss" scoped>
.game {
    margin: auto;
    width: 100%;
    border: none;
    display: block;
}
</style>
