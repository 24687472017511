import { defineStore } from 'pinia'
import { NitroFetchOptions } from 'nitropack'
import { useSentry } from '~/plugins/sentry'
import GeneralResponse from '~/models/Api/GeneralResponse'
import PwaUserDataResponse from '~/models/Api/PwaUserDataResponse'
import GiftEventProductsResponse from '~/models/Api/GiftEventProductsResponse'
import Basket from '~/models/Basket/Basket'
import BasketProduct from '~/models/Basket/BasketProduct'
import Product from '~/models/Product/Product'
import ProgressBar from '~/models/ProgressBar'
import GiftEventProductsResponseGiftEvent from '~/models/Api/GiftEventProductsResponseGiftEvent'
import ProductAvailability from '~/models/Product/ProductAvailability'
import AuthUser from '~/models/AuthUser'
import BasketDiscount from '~/models/Basket/BasketDiscount'
import BasketVoucher from '~/models/Basket/BasketVoucher'
import BasketProductPrice from '~/models/Basket/BasketProductPrice'
import BasketRaiffeisenBankDiscountData from '~/models/Basket/BasketRaiffeisenBankDiscountData'
import BasketOrderStreakData from '~/models/Basket/BasketOrderStreakData'
import ProductDelivery from '~/models/Product/ProductDelivery'
import CreateBasketUserRequest from '~/models/Api/Cart/CreateBasketUserRequest'
import useUidCookie from '~/composables/useUidCookie'

export const useBasketStore = defineStore('basket', {
    state: () => ({
        productAmounts: {} as { [key: number]: number },
        productUnitPrices: {} as { [key: number]: BasketProductPrice },
        basket: {
            uid: '',
            updatedAt: 0,
            products: [],
            deposits: [],
            vouchers: [],
            invalidVouchers: [],
            totalAmount: 0,
            shipping: null,
            payment: null,
            customer: null,
            discounts: [],
            price: {
                totalPrice: 0,
                afterDiscountPrice: 0,
                afterDiscountPriceNoVat: 0,
                productPrice: 0,
                absoluteDiscount: 0,
                totalDepositPrice: 0,
            },
            freeDelivery: {
                isEnabled: false,
                isFree: false,
                fromPrice: 0,
            },
            discountedDelivery: {
                isEnabled: false,
                isDiscounted: false,
                fromPrice: 0,
            },
            credits: {
                credits: 0,
                creditsInMoney: 0,
                possibleCredits: 0,
            },
            hasActivePremiumMembership: false,
            containsCsrProducts: false,
            containsOtcProducts: false,
            containsProductNotInStock: false,
            showMarketingCheckbox: false,
            premiumCareMembershipLogo: '',
            userPremiumInfo: null,
            rounding: 0,
            showExpressDeliveryModal: false,
            currencyRoundingAlert: '',
            raiffeisenBankDiscountData: {
                isEnabled: false,
                isCheckboxEnabled: false,
                isMarketingNotificationsCheckboxEnabled: false,
            } as BasketRaiffeisenBankDiscountData,
            hasPremiumFromGiftEvent: false,
            minOrderPrice: 0,
            premiumProductSelected: null,
            ekoMessage: '',
            premiumAlertMessage: '',
            orderStreakData: {
                isEnabled: false,
                minimalPrice: 0,
                creditLevel: [],
                lastOrderStreak: null,
                appUrl: '',
                appQrImg: '',
            } as BasketOrderStreakData,
        } as Basket,
        deliveryDateText: '',
        uid: null as string | null,

        customAddonSaleCarousels: [] as { title: string; products: Product[] }[],
        customAddonSaleCarouselsAreLoading: false,
        addonSaleAreas: {} as { title: string; products: Product[] }[],
        addonSaleAreasAreLoading: false,

        // new

        authUser: null as AuthUser | null,
        favoriteCount: 0,
        favoriteProductIds: [] as number[],
        progressBars: [] as ProgressBar[],
        gtmDataLayerUser: {
            type: 'anonymous',
        },
        voucherErrorMessage: '',
        giftEventActiveProducts: {} as { [key: number]: GiftEventProductsResponseGiftEvent },
        hasUserPremium: false,
        isInitialized: false,
        deliveries: [] as ProductDelivery[],
        fastestPossibleDeliveryDate: '',
    }),
    actions: {
        async fetchData(makeInitialization: boolean = true, forceUid: string = ''): Promise<PwaUserDataResponse | null> {
            this.isInitialized = true
            const config = useRuntimeConfig()
            const uidCookie = useUidCookie()
            const uid = forceUid || uidCookie.value || null
            const postData = {
                uid,
            }
            const accessToken = useAccessTokenCookie().value || ''

            if (!uid && !accessToken) {
                return null
            }

            const headers = {
                accessToken: useAccessTokenCookie().value || '',
            }

            const requestData = {
                method: 'POST',
                headers,
                body: postData,
            }

            let response
            // if (isInitial && Date.now() / 1000 - parseInt(localStorage.getItem('userDataResponseLastUpdated')) < 60) {
            //     // init from localStorage
            //     response = JSON.parse(localStorage.getItem('userDataResponse'))
            // }
            if (!response) {
                try {
                    const userDataResponse: GeneralResponse<PwaUserDataResponse> = await $fetch(
                        `${config.public.apiUrl}/api/v2/pwa/user-data`,
                        requestData as NitroFetchOptions<any>
                    )
                    response = userDataResponse.response

                    if (!response) {
                        useSentry()?.sentryCaptureException?.(
                            'User data response is undefined: ' +
                                `request data: ${JSON.stringify(requestData)};` +
                                `response: ${JSON.stringify(userDataResponse)}`
                        )

                        return null
                    }

                    // localStorage.setItem('userDataResponse', JSON.stringify(response))
                    // localStorage.setItem('userDataResponseLastUpdated', '' + Date.now() / 1000)
                } catch (e) {
                    // Cors for local development, hence try catch - should have not impact on production
                    return null
                }

                // localStorage.setItem('userDataResponse', JSON.stringify(response))
                // localStorage.setItem('userDataResponseLastUpdated', '' + Date.now() / 1000)
            }

            if (makeInitialization) {
                this.init(response)
            }

            return response
        },

        init(data: PwaUserDataResponse) {
            this.setUid(data.uid || '')
            this.setAuthUser(data.authUser)
            this.setFavoriteCount(data.favoriteCount)
            this.setFavoriteProductIds(data.favoriteProductIds)
            this.setProgressBars(data.progressBars)
            this.setGtmDataLayerUser(data.gtmDataLayerUser)
            this.setBasket(data.basket)
            this.setHasUserPremium(data.hasUserPremium)
        },

        setBasket(basket: Basket) {
            this.basket = basket

            const productsAndDeposits = [...basket.products, ...basket.deposits]
            productsAndDeposits.forEach((basketProduct) => {
                if (basketProduct.id && basketProduct.amount) {
                    this.setProductAmount({
                        productId: basketProduct.id,
                        amount: basketProduct.amount,
                    })
                    this.setProductUnitPrice({
                        productId: basketProduct.id,
                        unitPrice: basketProduct.price,
                    })
                }
            })
        },

        async refreshDeliveryDateText() {
            const uidCookie = useUidCookie()
            const response = await useNuxtApp().$repository.viewBasketDeliveryDateText(uidCookie.value)
            this.deliveryDateText = response.response.deliveryDate
        },

        async refreshDeliveries() {
            const uidCookie = useUidCookie()
            const response = await useNuxtApp().$repository.getFastestPossibleDeliveryDate(uidCookie.value)
            this.deliveries = response.response.deliveries
            this.fastestPossibleDeliveryDate = response.response.deliveryDate
        },

        async setAddress(street: string, city: string, postCode: string) {
            const uidCookie = useUidCookie()
            let uid = uidCookie.value
            const accessToken = useAccessTokenCookie().value

            if (!uid) {
                uid = uidCookie.value = await useNuxtApp().$repository.generateBasketUid(accessToken || '')
            }

            const basketUserData = reactive({
                firstName: this.basket.customer?.invoiceAddress.firstName || '',
                lastName: this.basket.customer?.invoiceAddress.lastName || '',
                email: this.basket.customer?.email || '',
                phone: (this.basket.customer?.invoiceAddress.phonePrefix || '') + (this.basket.customer?.invoiceAddress.phone || ''),
                street: this.basket.customer?.invoiceAddress.street || '',
                city: this.basket.customer?.invoiceAddress.city || '',
                postCode: this.basket.customer?.invoiceAddress.postCode || '',
                company: this.basket.customer?.invoiceAddress.company || '',
                note: this.basket.customer?.note || '',

                // invoice
                hasCompanyInfo: this.basket.customer?.hasCompanyInfo || false,
                ico: this.basket.customer?.ico || '',
                dic: this.basket.customer?.dic || '',
                icDph: this.basket.customer?.icDph || '',

                // del address
                delFirstName: this.basket.customer?.deliveryAddress.firstName || '',
                delLastName: this.basket.customer?.deliveryAddress.lastName || '',
                delStreet: this.basket.customer?.deliveryAddress.street || '',
                delCity: this.basket.customer?.deliveryAddress.city || '',
                delPostCode: this.basket.customer?.deliveryAddress.postCode || '',
                delCompany: this.basket.customer?.deliveryAddress.company || '',
                delPhone: (this.basket.customer?.deliveryAddress.phonePrefix || '') + (this.basket.customer?.deliveryAddress.phone || ''),

                marketingCheckboxChecked: false,
                conditions: false,
            })

            basketUserData.postCode = postCode
            basketUserData.street = street
            basketUserData.city = city

            if (basketUserData.delPostCode) {
                basketUserData.delPostCode = basketUserData.postCode
            }
            if (basketUserData.delStreet) {
                basketUserData.delStreet = basketUserData.street
            }
            if (basketUserData.delCity) {
                basketUserData.delCity = basketUserData.city
            }
            const createBasketUserRequestData: CreateBasketUserRequest = {
                uid,
                ...basketUserData,
            }

            try {
                await useNuxtApp().$repository.createBasketUser(createBasketUserRequestData, accessToken)
            } catch (e) {
                console.error(e)
            }
        },

        async loadOutOfStockAvailabilityTexts() {
            const config = useRuntimeConfig()
            const uidCookie = useUidCookie()
            const uid = uidCookie.value || null
            const requestData = {
                method: 'POST',
                body: { uid },
            }

            const response: GeneralResponse<{ availabilities: { availability: ProductAvailability; productId: number }[] }> = await $fetch(
                `${config.public.apiUrl}/api/v2/basket/availability-texts`,
                requestData as NitroFetchOptions<any>
            )
            if (response.code === 300 || !response.response?.availabilities) {
                return
            }

            const texts = response.response.availabilities
            texts.forEach((productTextPair) => {
                this.basket.products.forEach((product: BasketProduct) => {
                    if (product.id !== productTextPair.productId) {
                        return
                    }
                    product.availability = productTextPair.availability
                })
            })
        },

        setProgressBars(progressBars: ProgressBar[]) {
            this.progressBars = progressBars
        },

        setFavoriteProductIds(favoriteProductIds: number[]) {
            this.favoriteProductIds = favoriteProductIds
        },

        setFavoriteCount(favoriteCount: number) {
            this.favoriteCount = favoriteCount
        },

        setProductsPrice(productsPrice: number) {
            this.basket.price.productPrice = productsPrice
        },

        setAuthUser(authUser: AuthUser) {
            this.authUser = authUser
        },

        setUid(uid: string) {
            this.uid = uid
        },

        setTotalAmount(totalAmount: number) {
            this.basket.totalAmount = totalAmount
        },

        calculateFreeDelivery(afterDiscountPrice: number) {
            if (isNaN(afterDiscountPrice) || !this.basket.freeDelivery.fromPrice) {
                return
            }

            this.basket.price.afterDiscountPrice = afterDiscountPrice
            this.basket.freeDelivery.isFree = afterDiscountPrice >= this.basket.freeDelivery.fromPrice
            this.basket.discountedDelivery.isDiscounted = afterDiscountPrice >= this.basket.discountedDelivery.fromPrice
        },

        calculateAfterDiscountPrice() {
            let totalPrice = 0
            totalPrice += this.calculateProductsPrice()
            this.basket.discounts.forEach((discount: BasketDiscount) => {
                totalPrice += discount.price
            })
            this.basket.vouchers.forEach((voucher: BasketVoucher) => {
                totalPrice += voucher.price
            })

            return totalPrice
        },

        calculateProductsPrice() {
            let totalPrice = 0
            this.basket.products.forEach((product: BasketProduct) => {
                if (product.status === 1) {
                    totalPrice += product.price.price * (this.productAmounts[product.id] || 0)
                }
            })

            return totalPrice
        },

        calculateProductPriceAfterDiscount(productId: number) {
            let productPrice = 0
            this.basket.products.forEach((product: BasketProduct) => {
                if (productId === product.id && product.status === 1) {
                    productPrice += product.price.price * (this.productAmounts[product.id] || 0)
                }
            })

            this.basket.discounts.forEach((discount: BasketDiscount) => {
                if (productId === discount.productId) {
                    productPrice += discount.price * (discount.amount || 1)
                }
            })

            return productPrice
        },

        calculateTotalAmount() {
            let totalAmount = 0
            this.basket.products.forEach((product: BasketProduct) => {
                totalAmount += this.productAmounts[product.id] || 0
            })

            return totalAmount
        },

        changeAmount(productId: number, amount: number) {
            this.productAmounts[productId] = amount
        },

        setProductAmount({ productId, amount }: { productId: number; amount: number }) {
            this.productAmounts[productId] = amount
        },

        setProductUnitPrice({ productId, unitPrice }: { productId: number; unitPrice: BasketProductPrice }) {
            setTimeout(() => {
                this.productUnitPrices[productId] = unitPrice
            }, 2000)
        },

        setCustomAddonSaleCarousels(carousels: { title: string; products: Product[] }[]) {
            this.customAddonSaleCarousels = carousels
        },

        setCustomAddonSaleCarouselsAreLoading(customAddonSaleCarouselsAreLoading: boolean) {
            this.customAddonSaleCarouselsAreLoading = customAddonSaleCarouselsAreLoading
        },

        setAddonSaleAreas(areas: { title: string; products: Product[] }[]) {
            this.addonSaleAreas = areas
        },

        setAddonSaleAreasAreLoading(addonSaleAreasAreLoading: boolean) {
            this.addonSaleAreasAreLoading = addonSaleAreasAreLoading
        },

        setGtmDataLayerUser(gtmDataLayerUser: any) {
            this.gtmDataLayerUser = gtmDataLayerUser
        },

        addBasketProduct(product: BasketProduct) {
            this.basket.products.push(product)
            this.basket.products = [...new Map(this.basket.products.map((item: BasketProduct) => [item.id, item])).values()]
        },

        setVoucherErrorMessage(message: string) {
            this.voucherErrorMessage = message
        },

        setActiveProducts(giftEventProductsResponse: GiftEventProductsResponse) {
            giftEventProductsResponse.giftEvents.forEach((giftEventProducts) => {
                this.giftEventActiveProducts[giftEventProducts.id] = giftEventProducts
            })
        },

        getGiftEventActiveProducts(giftEventId: number): GiftEventProductsResponseGiftEvent {
            return this.giftEventActiveProducts[giftEventId] || []
        },

        setHasUserPremium(hasUserPremium: boolean) {
            this.hasUserPremium = hasUserPremium
        },

        hasProduct(productId: number) {
            return this.productAmounts[productId] > 0
        },

        getProductIds(): number[] {
            return Object.keys(this.productAmounts).map(Number)
        },
    },
})
