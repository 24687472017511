import type { RouteLocationNormalized } from 'vue-router'

export default defineNuxtRouteMiddleware((to: RouteLocationNormalized) => {
    const paramsToInspect = [/property\[\d+\]\[from|to|unit\]/, 'priceFrom', 'priceTo']
    const valuesToDelete = ['%from%', '%to%', '%unit%']
    const paramsToDelete: string[] = []

    for (const queryParam in to.query) {
        if (
            paramsToInspect.some((param) => {
                return queryParam.match(param)
            })
        ) {
            valuesToDelete.some((value) => {
                if (to.query[queryParam] === value) {
                    paramsToDelete.push(queryParam)
                    return true
                }

                return false
            })
        }
    }

    if (paramsToDelete.length === 0) {
        return
    }

    const url = new URL(to.fullPath, 'https://www.pilulka.cz')
    const params = new URLSearchParams(url.search)
    for (const param of paramsToDelete) {
        params.delete(param)
    }

    if (!params.toString()) {
        return navigateTo(url.pathname, { redirectCode: 301 })
    }

    return navigateTo(`${url.pathname}?${params.toString()}`, { redirectCode: 301 })
})
