import type { RouteLocationNormalized } from 'vue-router'

export default defineNuxtRouteMiddleware((to: RouteLocationNormalized) => {
    const paramsToDelete = ['gtmClickEvent', 's', 'pwa', 'product_review_list', 'dis', 'fs', 'warehouseId', 'do', 'p1', 'p2']

    const anyParamExists = paramsToDelete.some((param) => {
        return to.query[param] !== undefined
    })

    if (!anyParamExists) {
        return
    }

    const url = new URL(to.fullPath, 'https://www.pilulka.cz') // hack - we use only the path and search part
    const params = new URLSearchParams(url.search)

    for (const param of paramsToDelete) {
        params.delete(param)
    }

    if (!params.toString()) {
        return navigateTo(url.pathname, { redirectCode: 301 })
    }

    return navigateTo(`${url.pathname}?${params.toString()}`, { redirectCode: 301 })
})
